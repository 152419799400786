<template>
  <div style="width:99%;margin:auto;">
    <div class="form-title">商品详情</div>
    <div class="approve">
      <div class="basic-info-container">
        <h3 class="basic-info">基础信息</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div class="grid-content1">名称：<span class="col-text">{{detailData.name}}</span></div>
            <div class="grid-content1">单价：<span class="col-text red">￥{{detailData.unitPrice}}</span></div>
            <div class="grid-content1">编码：<span class="col-text">{{detailData.code}}</span></div>
            <div class="grid-content1">分类：<span class="col-text">{{detailData.goodsTypeName}}</span></div>
            <div class="grid-content1">品牌：<span class="col-text">{{detailData.brandName}}</span></div>
            <div class="grid-content1">规格型号：<span class="col-text">{{detailData.model}}</span></div>
            <div class="grid-content1">商品图片：<UploadImg v-model="detailData.thumbnailList" :limit="1" :disabled="true"></UploadImg></div>
            <div class="grid-content1">详情图片：<UploadImg v-model="detailData.detailList" :limit="1" :disabled="true"></UploadImg></div>
            <div class="grid-content1">配方图片：<UploadImg v-model="detailData.formulaList" :limit="1" :disabled="true"></UploadImg></div>
          </div>
        </div>
      </div>
      <div class="basic-info-container">
        <h3 class="basic-info">计量单位</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div class="grid-content1">销售单位：<span class="col-text">{{detailData.saleMeasureUnitName}}</span></div>
            <div class="grid-content1">库存单位：<span class="col-text">{{detailData.stockMeasureUnitName}}</span></div>
            <div class="grid-content1">报表单位：<span class="col-text">{{detailData.reportMeasureUnitName}}</span></div>
          </div>
        </div>
      </div>
  

    </div>
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
    </div>
  </div>
</template>

<script>
import { getGoodsInfo } from '@/api/index.js'
import UploadImg from '@/components/UploadImg/index.vue'
export default {
  name: 'CustomerForm',
  components: { UploadImg },
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      activeNames: ['1', '2', '3', '4'],
      detailData:{
        buyerInfor:{}
      },
      goodsList:[]
    }
  },
  created() {
    this.getGoodsInfo();
  },
  mounted() {
    
  },
  methods: {
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
    //编辑获取详情
    getGoodsInfo(){
      let param = {id:this.editId};      
      getGoodsInfo(param).then(res => {
        if (res.code != 200) return
          this.detailData = res.data;     
      })
    },
    

  },
}
</script>

<style scoped>

</style>

<style lang="scss" scoped>
@import '@/assets/css/info.scss';
</style>
