<template>
  <div style="width:100%">
    <GoodsList v-if="editType == 'list'"  @changeShowType="changeShowType"></GoodsList>
    <GoodsForm v-if="editType == 'edit' || editType == 'add'" :editId="editId"  :editType="editType"  @changeShowType="changeShowType"></GoodsForm>
    <GoodsInfo v-if="editType == 'show'" :editId="editId"   @changeShowType="changeShowType"></GoodsInfo>
  </div>
</template>

<script>
import GoodsList from './components/GoodsList'
import GoodsForm from './components/GoodsForm'
import GoodsInfo from './components/GoodsInfo'

export default {
  name: 'goods',
  components: { GoodsForm,GoodsList,GoodsInfo },
  data() {
    return {
      editType:'list',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id){
      if(type!='add'){
        this.editId = id;
      }
      this.editType = type;
    }
  }
}
</script>

<style>
</style>