<template>
  <div>
    <div class="form-title">{{editType | typeName}}商品信息</div>
    <el-form size="small" inline ref="form" :model="form" label-width="auto"  >
    <el-collapse v-model="activeNames">
      <el-collapse-item title="基础信息" name="1">
        <div style="text-align:center;">
        <div style="margin:auto;">
          <el-form-item label="名称" prop="name" :rules="[{ required: true, message: '名称不可为空', trigger: 'blur' }]">
            <el-input v-model="form.name" placeholder="请输入名称" style="width:300px" :disabled="disabled"></el-input>
          </el-form-item>          
          <el-form-item label="单价" prop="unitPrice" :rules="[{ required: true, message: '单价不能为空', trigger: 'blur' }]" >
              <el-input v-model="form.unitPrice" type="number" placeholder="请输入单价" :disabled="disabled" style="width:300px"></el-input>
          </el-form-item>
        </div>
        <div style="margin:auto;">
          <el-form-item label="编码" prop="code" :rules="[{ required: true, message: '请输入编码', trigger: 'blur' }]" >
              <el-input v-model="form.code" maxlength="50" placeholder="请输入编码" :disabled="disabled" style="width:300px"></el-input>
          </el-form-item>
          <el-form-item label="分类" prop="goodsTypeId" :rules="[{ required: true, message: '请选择分类', trigger: 'blur' }]" >
               <ChooseGoodsType v-model="form.goodsTypeId"  @change="goodsTypeChange" :disabled="disabled"  style="width:300px"></ChooseGoodsType>
          </el-form-item>
        </div>

        <div style="margin:auto;">
          <el-form-item label="品牌" prop="brandId" :rules="[{ required: true, message: '请选择品牌', trigger: 'blur' }]">
              <ChooseBrand v-model="form.brandId" @change="brandChange"  style="width:300px" :disabled="disabled"></ChooseBrand>
          </el-form-item>

          <el-form-item label="规格型号" prop="model" :rules="[{ required: true, message: '请输入规格型号', trigger: 'blur' }]" >
              <el-input  v-model="form.model" placeholder="请输入规格型号" style="width:300px" :disabled="disabled"></el-input>
          </el-form-item>
        </div>
        <div style="width:775px;text-align:left;margin:auto;">          
          <el-form-item label="商品图片" prop="thumbnailList" :rules="[{ required: true, message: '请选择图片', trigger: 'blur' }]" >
              <UploadImg v-model="form.thumbnailList" :limit="1" :disabled="disabled"></UploadImg>
          </el-form-item>
        </div>
         <div style="width:775px;text-align:left;margin:auto;">          
          <el-form-item label="详情图片" prop="detailList" :rules="[{ required: true, message: '请选择图片', trigger: 'blur' }]" >
              <UploadImg v-model="form.detailList" :limit="1" :disabled="disabled"></UploadImg>
          </el-form-item>
        </div>
         <div style="width:775px;text-align:left;margin:auto;">          
          <el-form-item label="配方图片" prop="formulaList" :rules="[{ required: true, message: '请选择图片', trigger: 'blur' }]" >
              <UploadImg v-model="form.formulaList" :limit="1" :disabled="disabled"></UploadImg>
          </el-form-item>
        </div>

        </div>

        
      </el-collapse-item>

      <el-collapse-item title="计量单位" name="1">

        <div style="text-align:center">
          <el-form-item label="销售单位" prop="saleMeasureUnitId" :rules="[{ required: true, message: '请选择销售单位', trigger: 'blur' }]" >
              <ChooseUnit v-model="form.saleMeasureUnitId"  @change="saleMeasureUnitChange"  style="width:300px" :disabled="disabled"></ChooseUnit>
          </el-form-item>

          <el-form-item label="库存单位" prop="stockMeasureUnitId" :rules="[{ required: true, message: '请选择库存单位', trigger: 'blur' }]">
              <ChooseUnit v-model="form.stockMeasureUnitId" @change="stockMeasureUnitChange"  style="width:300px" :disabled="disabled"></ChooseUnit>
          </el-form-item>
        </div>
        <div style="width:775px;text-align:left;margin:auto;">
          <el-form-item label="报表单位" prop="reportMeasureUnitId" :rules="[{ required: true, message: '请选择报表单位', trigger: 'blur' }]">
              <ChooseUnit v-model="form.reportMeasureUnitId" @change="reportMeasureUnitChange" style="width:300px;" :disabled="disabled"></ChooseUnit>
          </el-form-item>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
      <el-button type="primary" v-debounce="submit" v-if="editType!='show'" >保存</el-button>
    </div>

    </el-form>


  </div>
</template>

<script>
import { addGoodsInfo, updateGoodsInfo,getGoodsInfo } from '@/api/index.js'
import UploadImg from '@/components/UploadImg/index.vue'
import ChooseUnit from '@/components/ChooseUnit/index.vue'
import ChooseBrand from '@/components/ChooseBrand/index.vue'
import ChooseGoodsType from '@/components/ChooseGoodsType/index.vue'
export default {
  name: 'CustomerForm',
  components: { UploadImg,ChooseUnit,ChooseBrand,ChooseGoodsType },
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      activeNames: ['1', '2'],
      form:{
        id:'',
        goodsTypeId:'',
        goodsTypeName:'',
        brandId:'',
        brandName:'',
        name:'',
        model:'',
        saleMeasureUnitId:'',
        saleMeasureUnitName:'',
        stockMeasureUnitId:'',
        stockMeasureUnitName:'',
        reportMeasureUnitId:'',
        reportMeasureUnitName:'',
        unitPrice:'',
        thumbnailList:[],
        detailList:[],
        formulaList:[]
      },
      disabled:false,
    }
  },
  created() {
    this.disabled = this.editType=='show';
    if (this.editType !='add' ) {
      this.form.id = this.editId;
      this.getGoodsInfo();
    }
  },
  mounted() {
    
  },
  methods: {
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
    goodsTypeChange(item){
      this.form.goodsTypeName = item.name
    },
    brandChange(item){
      this.form.brandName = item.name
    },
    saleMeasureUnitChange(item){
      this.form.saleMeasureUnitName = item.name;
    },
    stockMeasureUnitChange(item){
      this.form.stockMeasureUnitName = item.name;
    },
    reportMeasureUnitChange(item){
      this.form.reportMeasureUnitName = item.name;
    },
    //编辑获取详情
    getGoodsInfo(){
      let param = {id:this.editId};
      
      getGoodsInfo(param).then(res => {
        if (res.code != 200) return
        this.form = res.data;        
      })
    },
    submit() {
     if (!this.$validate('form')) return
      let form = JSON.parse(JSON.stringify(this.form));
      let success = res => {
        if (res.code != 200) {
          this.$message.error(res.msg || '操作失败')
          return
        }
        this.$message.success('操作成功')
        this.cancel()
      }
      if (this.editType == 'add') {
        addGoodsInfo(form).then(success)
      } else {
        updateGoodsInfo(form).then(success)
      }
    },
  },
}
</script>
